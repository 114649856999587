var generic = generic || {};
var site = site || {};

(function($) {
  var countryChooser = {

    initCountryChooser: function(context) {
      var $templateContainer = $('.js-gnav-country-language-chooser-v1', context);
      var $countrySection = $('.js-gnav-country-section', $templateContainer);
      var $countrySelector = $('.js-gnav-country-chooser', $templateContainer);
      var $languageSection = $('.js-gnav-language-section', $templateContainer);
      var $languageSelector = $('.js-gnav-language-chooser', $templateContainer);
      var $countryMenu = $countrySection.find('.menu');
      var $languageMenu = $languageSection.find('.menu');
      var $languageLink = $languageMenu.find('a');
      var $countryLink = $countryMenu.find('a');
      var prefLang, prefCoun, cartItemCount, listLanguages;
      var region = $countryLink.find('p').data('region');

      if (typeof site.setLocaleFromUrl === 'function') { //EMEAFEATUR-9732 - Setting LOCALE cookie from url params for hub
        site.setLocaleFromUrl();
      }
      var locale = generic.cookie('LOCALE');
      if (Drupal.settings.common.is_hub_site) {
        locale = locale || 'en_EU';
      }

      if (locale !== '' && locale != null) {
        prefLang = locale.split('_')[0];
        prefCoun = locale.split('_')[1];
      }
      if (region !== '' && region === 'EU') {
        $countryLink.each(function() {
          var $this = $(this);
          var $dataElement = $this.find('p');
          if ($dataElement.data('country') === prefCoun.toLowerCase()) {
            var $innerElement = $countrySelector.find('span');
            $countrySelector.html($dataElement.data('countryname')).append($innerElement);
            listLanguages = $dataElement.data('languages');
          }
        });
        $languageLink.each(function() {
          var $this = $(this);
          var $dataElement = $this.find('p');
          if ($dataElement.data('language') === prefLang.toLowerCase()) {
            var $innerElement = $languageSelector.find('span');
            $languageSelector.html($dataElement.text()).append($innerElement);
          }
          if (listLanguages && !listLanguages.includes($dataElement.data('language'))) {
            $this.hide();
          }
        });
        cartItemCount = 0;
        $(document).on('addToCart.success', function(e, cartResponse) {
          if (cartResponse.trans_data.items_count) {
            cartItemCount = cartResponse.trans_data.items_count;
          } else {
            cartItemCount = cartResponse;
          }
        });
        $countryLink.once().click(function(e) {
          e.preventDefault();
          var $dataElement = $(this).find('p');
          prefLang = site.getAltLanguage(prefLang, $dataElement.data('languages').split(','));
          countryChooser.requestChangeLocale($dataElement.data('country'), prefLang, cartItemCount, $templateContainer);
        });
      }
      // Initialize the country chooser
      $countrySelector.once().click(function(e) {
        e.preventDefault();
        countryChooser.slideMenuUp($(this), $countryMenu);
      });

      $languageSelector.once().click(function(e) {
        e.preventDefault();
        countryChooser.slideMenuUp($(this), $languageMenu);
      });

      $countrySelector.on('clickoutside', function() {
        countryChooser.slideDown($countrySelector, $countryMenu);
      });

      $languageSelector.on('clickoutside', function() {
        countryChooser.slideDown($countrySelector, $languageMenu);
      });
      $languageLink.once().on('click', function(e) {
        e.preventDefault();
        var language = $(this).find('p').data('language');
        if (region !== '' && region === 'EU') {
          site.changeLocale(prefCoun, language);
        }
        countryChooser.changeLanguage(language, $(this).attr('href'));
      });
    },
    slideMenuUp: function($element, $menu) {
      $element.addClass('active');
      $menu.find('a').attr('target', '_self');
      $menu.stop().delay(100).slideToggle(300, function() {
        $menu.is(':visible') || $element.removeClass('active');
        $menu.css({ 'overflow': 'auto' });
      });
    },
    slideDown: function($element, $menu) {
      $menu.slideUp(200);
      $element.removeClass('active');
    },
    changeLanguage: function(lang, domine) {
      var postfixes = {};
      postfixes[lang] = lang;
      var locale = generic.cookie('LOCALE');
      var domainName = domine.replace(/\/$/, '');
      var country;

      if (locale != null && locale !== '') {
        var localeParts = locale.split('_');
        country = localeParts[1].toLowerCase();
      }
      postfixes[country] = country.toLowerCase();
      var locale_cookie = lang + '_' + country.toUpperCase();
      // only switch if not already in requested language
      if (locale_cookie !== generic.cookie('LOCALE')) {
        var path = '';
        if (generic.cookie('LOCALE')) {
          path = window.location.pathname.replace(/^\/[a-z]{2}\/[a-z]{2}/, '');
        }
        document.cookie = 'LOCALE=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';

        generic.cookie('LOCALE', locale_cookie, {path: '/'});

        var url = domine + path;
        var queryParamsArray = window.location.href.split('?');
        if (typeof queryParamsArray[1] !== 'undefined') {
          url = domainName + path + '?' + queryParamsArray[1];
        }
        window.location.href = url;
      }
    },
    requestChangeLocale: function(prefC, prefL, cartItemCount, context) {
      var $localeSwitchWarning = $('.js-gnav-country-switch-overlay', context);
      if (cartItemCount > 0) {
        generic.overlay.launch({
          content: $localeSwitchWarning.html(),
          cssStyle: {
            width: '450px',
            height: '200px'
          },
          onComplete: function() {
            $('.js-gnav-country-change-button').on('click', function() {
              site.changeLocale(prefC, prefL);
            });
            $('.js-gnav-country-cancel-button').on('click', function() {
              $('#cboxClose').trigger('click');
            });
          }
        });
        site.centerModal($localeSwitchWarning);
      } else {
        site.changeLocale(prefC, prefL);
      }
    }
  };

  Drupal.behaviors.countryChooserV1 = {
    attach: function(context) {
      countryChooser.initCountryChooser(context);
    }
  };
})(jQuery);
